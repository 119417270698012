/* Homepage styling */
@use "./foundation/var" as *;
@use "./mixins/rigged-background" as *;

/* Homepage header */
#primary-page-header {
	border-bottom: 1px solid rgba(var(--scheme-dark-primary-rgb), 0.1);
}

#homepage-content {
	padding: 0;

	ul {
		display: flex;
		align-content: center;
		justify-content: space-between;
		column-gap: 4rem;
		padding: 0;
	}

	#welcome-image {
		margin-bottom: 0;
		margin-top: 0;
		visibility: hidden;
		display: none;
	}

	#welcome-text {
		margin: 4rem 0 0;

		#welcome-header {
			text-align: start;
			margin-top: 0;
		}

		#welcome-description {
			text-align: start;
			font-size: 1.4em;
		}

		.button,
		.inverted-button {
			margin-bottom: 0;
		}
	}

	@media screen and (min-width: 1200px) {
		padding-top: 5vw;
		padding-bottom: 5vw;

		#welcome-text {
			margin: 0;
			max-width: 500px;
		}

		#welcome-image {
			visibility: unset;
			display: unset;
			width: 45vw;
			padding: 0;
			border-radius: $default-border-radius;
			box-shadow: rgba($scheme-primary-rgb, 0.6) -5px 5px, rgba($scheme-primary-rgb, 0.4) -10px 10px,
				rgba($scheme-primary-rgb, 0.3) -15px 15px, rgba($scheme-primary-rgb, 0.2) -20px 20px,
				rgba($scheme-primary-rgb, 0.1) -25px 25px;
			transition: width 1s ease-in-out, box-shadow 1s ease-in-out;
		}

		#welcome-image:hover {
			width: 48vw;
			box-shadow: rgba($scheme-primary-rgb, 0.8) -5px 5px, rgba($scheme-primary-rgb, 0.6) -10px 10px,
				rgba($scheme-primary-rgb, 0.5) -15px 15px, rgba($scheme-primary-rgb, 0.4) -20px 20px,
				rgba($scheme-primary-rgb, 0.3) -25px 25px, rgba($scheme-primary-rgb, 0.2) -30px 30px,
				rgba($scheme-primary-rgb, 0.1) -30px 30px;
		}
	}

	@media screen and (min-width: 1300px) {
		#welcome-text {
			max-width: 600px;
		}
	}
}

$phone-homepage-margin: 2rem;
@media screen and (max-width: $large-screen-size) {
	/* Fancy background */
	.text-page-content-container {
		@include rigged-background();
		display: flex;
		align-items: center;
	}

	#homepage-content {
		margin: $phone-homepage-margin;
		align-items: center;

		ul {
			column-gap: 0;
		}

		#welcome-text {
			display: flex;
			margin: 0;
			justify-content: center;
			align-content: center;
			min-width: 400px; /* The screen is already at least 600px */

			/* Styling for better contrast to the background */
			max-width: 600px;
			background-color: white;
			border-radius: 1rem;
			padding: 2rem;

			#welcome-buttons {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-content: center;
				flex-grow: 1;
			}
		}
	}
}

@media screen and (max-width: $small-screen-size) {
	#homepage-content {
		margin: $phone-homepage-margin;

		#welcome-text {
			padding: 2rem;
			width: calc(100vw - 4 * 2rem); /* 4 times 2rem - this padding and the margin of the parent (both right & left) */
			min-width: calc(100vw - 4 * 2rem);

			#welcome-buttons {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-content: center;
				flex-grow: 1;
			}

			#welcome-description {
				text-align: start;
				font-size: 1.2em; /* Smaller font for phone - prev 1.4rem */
			}
		}
	}
}
